/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { H1, H3 } from "@robbie-cook/react-components";
import React from "react";
import slugify from "slugify";
import FileTileWrapper from "../components/FileTileWrapper";
import { FileAPI, ProjectAPI, UserSettingsAPI } from "../api/API";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import EditorPage from "./EditorPage";
import Tile from "../components/Tile";
import ProjectPage from "./ProjectPage";
import Settings from "../settings/components/Settings";

/**
 * Interface for HomePage props
 */
export interface HomePageProps {
  children?: any;
  user: firebase.User | null;
  projects: Array<string> | null;
  setProjects: (projects: Array<string>) => void;
}

/**
 *  A HomePage component.
 */
const HomePage: React.FC<HomePageProps> = (props) => {
  // const [files, setFiles] = React.useState<Array<string> | null>(null);

  console.log("projects", props.projects);

  return (
    <Switch>
      {props.projects?.map((item) => {
        console.log();
        return (
          <Route path={`/project/${item}/settings`} exact>
            <Settings title="Project Settings" settings={[]} />
          </Route>
        );
      })}
      <div>
        <H3>Select a project to edit</H3>

        <div
          css={css`
            display: flex;
            flex-wrap: wrap;

            & > * {
              margin: 10px 20px;
            }
          `}
        >
          {props.projects &&
            Array.isArray(props.projects) &&
            props.projects.map((project) => (
              <Tile
                name={project}
                onSettingsClick={() => {
                  window.location.href = `/project/${project}/settings`;
                }}
                onClick={() => (window.location.href = `/project/${project}`)}
              />
            ))}
          <Tile
            name="Make project"
            onClick={async () => {
              await ProjectAPI.createProject();
              UserSettingsAPI.getUserSettings(props.user).then((settings) => {
                if (settings && settings.projects) {
                  console.log("settings projects", settings.projects);
                  props.setProjects(settings.projects);
                }
              });
            }}
          />
        </div>
        {/* <Router>
        <Switch>
          {files &&
            files.map((file) => (
              <Route key="file" path={`/${encodeURIComponent(file)}`}>
                <EditorPage file={file} />
              </Route>
            ))}
        </Switch>
      </Router> */}
      </div>
    </Switch>
  );
};

export default HomePage;
