/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import slugify from "slugify";
import { ProjectAPI } from "../api/API";
import FileTileWrapper from "../components/FileTileWrapper";
import EditorPage from "./EditorPage";

/**
 * Interface for ProjectPage props
 */

export interface ProjectPageProps {
  children?: any;
  project: string;
  user: firebase.User | null;
}
/**
 *  A ProjectPage component.
 */
const ProjectPage: React.FC<ProjectPageProps> = (props) => {
  const [files, setFiles] = React.useState<Array<string> | null>();

  const { url } = useRouteMatch();

  const fileBasePath = `${url}/file`;

  React.useEffect(() => {
    ProjectAPI.getFiles(props.project).then((myfiles) => {
      console.log("myfiles", myfiles);
      setFiles(myfiles);
    });
  }, []);

  return (
    <div css={css``}>
      <Switch>
        <React.Fragment>
          {files?.map((file) => (
            <React.Fragment>
              <Route path={`${fileBasePath}/${file}`}>
                <EditorPage file={file} project={props.project} />
              </Route>
            </React.Fragment>
          ))}
          <Route path={url} exact>
            {files && (
              <FileTileWrapper
                files={files.map((myfile) => ({
                  name: myfile,
                  id: myfile,
                }))}
                onFileClick={(file) => {
                  (window as any).location = `${fileBasePath}/${file}`;
                }}
                onRemoveFile={async (file) => {
                  if (props.user) {
                    await ProjectAPI.deleteFile(props.project, file);
                    const files = await ProjectAPI.getFiles(props.project);
                    setFiles(files);
                  }
                }}
                onAddFile={async (file) => {
                  if (files) {
                    const newFiles = [...files];
                    const fileSlug = `${slugify(file)}.md`;
                    newFiles.push(fileSlug);

                    await ProjectAPI.writeFile(props.project, fileSlug, "");
                    setFiles(newFiles);
                  }
                }}
              />
            )}
          </Route>
        </React.Fragment>
      </Switch>
    </div>
  );
};

export default ProjectPage;
