import firebase from "firebase";

/**
 * A helper for accessing files
 */
export default class ProjectAPI {
  url = "";

  constructor(url: string) {
    this.url = `${url}/project`;
  }

  async createProject(): Promise<string | null> {
    const authToken = await firebase.auth().currentUser?.getIdToken();
    if (authToken) {
      return (
        await fetch(`${this.url}/create`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${authToken}`,
          },
        })
      ).text();
    }
    return null;
  }

  /**
   * Upload a file using the API
   */
  async writeFile(
    project: string,
    fileSlug: string,
    content: string
  ): Promise<Response | null> {
    const authToken = await firebase.auth().currentUser?.getIdToken();
    if (authToken) {
      return fetch(`${this.url}/${project}/file/${fileSlug}`, {
        method: "POST",
        body: JSON.stringify({
          content,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${authToken}`,
        },
      });
    }
    return null;
  }

  /**
   * Remove a file using the API
   */
  async deleteFile(
    project: string,
    fileSlug: string
  ): Promise<Response | null> {
    const authToken = await firebase.auth().currentUser?.getIdToken();

    if (authToken) {
      // TODO: move to helper
      return fetch(`${this.url}/${project}/delete-file/${fileSlug}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    }
    return null;
  }

  /**
   * Fetching all files.
   *
   * TODO: refactor name
   */
  async getFiles(project: string): Promise<Array<string> | null> {
    const authToken = await firebase.auth().currentUser?.getIdToken();

    if (authToken) {
      const data = await fetch(`${this.url}/${project}/files`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return data.json();
    }
    return null;
  }

  /**
   * Fetching a specific file
   */
  async getFile(project: string, filename: string): Promise<Response> {
    return await fetch(`${this.url}/${project}/file/${filename}`, {
      headers: {
        Authorization: `Bearer ${await firebase
          .auth()
          .currentUser?.getIdToken()}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
}
