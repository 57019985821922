/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Button, H1, P } from "@robbie-cook/react-components";
import React, { ReactElement, ReactNode } from "react";

/**
 * Interface for Settings props
 */
export interface SettingsProps {
  children?: any;
  title: string;
  settings: {
    id: string,
    name: string,
    value: string,
    jsx?: ReactNode
  }[];
}

const SettingWrapper: React.FC<{ children: any }> = (props) => (
  <div
    css={css`
      display: flex;
      align-items: center;

      & > * {
        margin-left: 10px !important;
        margin-right: 10px !important;
      }
    `}
  >
    {props.children}
  </div>
);

/**
 *  A Settings component.
 */
const Settings: React.FC<SettingsProps> = (props) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <H1>{props.title}</H1>
      <div
        css={css`
          margin-top: 20px;
        `}
      >
        {Object.entries(props.settings).map(([key, data]) => (
          <SettingWrapper>
            <p>{key}:</p>
            {(() => {
              if (Array.isArray(data)) {
                return <p>{data.join(", ")}</p>;
              } else {
                return data;
              }
            })()}
          </SettingWrapper>
        ))}
      </div>
    </div>
  );
};

export default Settings;
