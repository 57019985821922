import * as firebase from "firebase";

import FileAPIClass from "./ProjectAPI";
import UserSettingsAPIClass from "./UserSettingsAPI";
import ProjectAPIClass from "./ProjectAPI";

/**
 * A FileHelper.
 */

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://test-api.fox.avenueci.com";

export const FileAPI = new FileAPIClass(url);
export const UserSettingsAPI = new UserSettingsAPIClass(url);
export const ProjectAPI = new ProjectAPIClass(url);
