/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Avatar, Dropdown, Menu, P } from "@robbie-cook/react-components";
import React from "react";

/**
 * Interface for Component props
 */
export interface ComponentProps {
  children?: any;
  user: firebase.User;
  onLogOut: () => void;
}

const UserSection: React.FC<ComponentProps> = (props) => {
  return (
    <div>
      <Dropdown
        // overlay={() => <div>hyello</div>}
        overlay={
          <Menu>
            <Menu.Item>
              <a href="/settings" rel="noopener noreferrer">
                Settings
              </a>
            </Menu.Item>
            <Menu.Item onClick={props.onLogOut}>Log Out</Menu.Item>
          </Menu>
        }
      >
        <div
          css={css`
            display: flex;
            align-items: center;

            & > * {
              margin: 0 10px;
            }
          `}
        >
          {props.user.photoURL && (
            <Avatar src={props.user.photoURL} alt="profile image" />
          )}
          <P>{props.user.displayName}</P>
          {/* <Cog
    css={css`
      color: white;
      transform: scale(1.9);
      margin-left: 16px;
      cursor: pointer;
    `}
  /> */}
        </div>
      </Dropdown>
    </div>
  );
};

export default UserSection;
