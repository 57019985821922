/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import Editor from "./components/Editor";
import EditorPage from "./pages/EditorPage";
import Tile from "./components/Tile";
import FileTileWrapper from "./components/FileTileWrapper";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import slugify from "slugify";
import LogInSection from "./authentication/components/LogInSection";
import * as firebase from "firebase";

import {
  H1,
  H3,
  ReactComponentsContext,
  Layout,
  Dropdown,
  Menu,
} from "@robbie-cook/react-components";
import UserSettingsPage from "./pages/UserSettingsPage";
import HomePage from "./pages/HomePage";
import ProjectPage from "./pages/ProjectPage";
import { UserSettingsAPI } from "./api/API";
import ProjectSettingsPage from "./pages/ProjectSettingsPage";

import Magic from "magic-memo";

const ProjectTransformer: React.FC<{
  children?: any;
  user: firebase.User | null;
}> = (props) => {
  let { project } = useParams();
  return <ProjectPage project={project} user={props.user} />;
};

function App() {
  const [projects, setProjects] = React.useState<Array<string> | null>(null);

  const [signedIn, setSignedIn] = React.useState<boolean>(false);

  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      if (!projects) {
        await UserSettingsAPI.getUserSettings(user).then((settings) => {
          if (settings?.projects && !projects) {
            console.log("me projects", settings.projects);
            setProjects(settings.projects);
          }
        });
        setSignedIn(true);
      }
    }
  });

  return (
    <ReactComponentsContext.Provider value={{ type: "light" }}>
      <Layout.Header
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <ReactComponentsContext.Provider value={{ type: "dark" }}>
          <a href="/">
            <H3>Fox</H3>
          </a>
          <LogInSection
            onLogIn={() => {}}
            currentUser={firebase.auth().currentUser}
            onLogOut={async () => {
              await firebase.auth().signOut();
              window.location.reload();
            }}
          />
        </ReactComponentsContext.Provider>
      </Layout.Header>

      <main
        css={css`
          padding: 30px 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        `}
      >
        <Router>
          <Switch>
            <Route path={`/project/:project`}>
              <React.Fragment>
                <ProjectTransformer user={firebase.auth().currentUser} />
              </React.Fragment>
            </Route>
            <Route path="/settings">
              <UserSettingsPage user={firebase.auth().currentUser} />
            </Route>
            <Route path="*">
              <HomePage
                user={firebase.auth().currentUser}
                projects={projects}
                setProjects={setProjects}
              />
            </Route>
          </Switch>
        </Router>
      </main>
    </ReactComponentsContext.Provider>
  );
}

export default App;
