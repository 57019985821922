/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import LogInButton from "./LogInButton";
import { Avatar, P, Dropdown, Menu } from "@robbie-cook/react-components";
import Cog from "./Cog";
import UserSection from "./UserSection";

/**
 * Interface for LoginSectiomnm props
 */
export interface LoginSectionProps {
  children?: any;
  currentUser: firebase.User | null;
  onLogIn: (user: firebase.User) => void;
  onLogOut: () => void;
}

/**
 *  A LoginSectiomnm component.
 */
const LoginSection: React.FC<LoginSectionProps> = (props) => {
  if (props.currentUser) {
    return <UserSection user={props.currentUser} onLogOut={props.onLogOut} />;
  } else return <LogInButton onLogIn={props.onLogIn} />;
};

export default LoginSection;
