/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import * as firebaseui from "firebaseui";
import * as firebase from "firebase";
import { Button } from "@robbie-cook/react-components";
import { FirebaseArrayIndexError } from "firebase-admin";

firebase.initializeApp({
  apiKey: "AIzaSyDdU6U0qDQS0gPRvVB_NlLEJsAKFNM40oo",
  authDomain: "fox-ave.firebaseapp.com",
  databaseURL: "https://fox-ave.firebaseio.com",
  projectId: "fox-ave",
  storageBucket: "fox-ave.appspot.com",
  messagingSenderId: "491899277581",
  appId: "1:491899277581:web:71f5e6351a54bf66306044",
  measurementId: "G-0WSD8JCEPE",
});

const provider = new firebase.auth.GoogleAuthProvider();

const uiConfig = {
  signInSuccessUrl: "/#",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  // tosUrl: "<your-tos-url>",
  // Privacy policy url/callback.
  // privacyPolicyUrl: function () {
  //   window.location.assign("<your-privacy-policy-url>");
  // },
};

/**
 * Interface for LogInButton props
 */
interface LogInButtonProps {
  children?: any;
  onLogIn: (user: firebase.User) => void;
}

/**
 *  A LogInButton component.
 */
const LogInButton: React.FC<LogInButtonProps> = (props) => {
  return (
    <Button
      onClick={() => {
        // https://firebase.google.com/docs/auth/web/google-signin?authuser=0
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(async (result) => {
            if (result.user) {
              props.onLogIn(result.user);
            }
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            const credential = error.credential;

            console.error(errorCode, errorMessage);
            // ...
          });
      }}
    >
      Log in
    </Button>
  );
};

export default LogInButton;
