import firebase from "firebase";

interface UserSettings {
  projects?: Array<string>;
  apiToken?: Array<string>;
}

/**
 * A helper for accessing files
 */
export default class UserSettingsAPI {
  url = "";

  constructor(url: string) {
    this.url = url;
  }

  /**
   * Get a user's settings
   */
  async getUserSettings(
    user: firebase.User | null
  ): Promise<UserSettings | null> {
    const authToken = await user?.getIdToken();
    return (
      await fetch(`${this.url}/user/settings`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
    ).json();
  }
}
