/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Settings from "../settings/components/Settings";
import * as firebase from "firebase";
import { UserSettingsAPI } from "../api/API";

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080/"
    : "https://test-api.fox.avenueci.com/";

/**
 * Interface for UserSettings props
 */
export interface UserSettingsPageProps {
  children?: any;
  user: firebase.User | null;
}

/**
 * A UserSettings component.
 */
const UserSettingsPage: React.FC<UserSettingsPageProps> = (props) => {
  const [userSettings, setUserSettings] = React.useState({});
  React.useEffect(() => {
    if (props.user) {
      UserSettingsAPI.getUserSettings(props.user).then((data) => {
        if (data) {
          setUserSettings(data);
        }
      });
    }
  }, [props.user]);
  return (
    <Settings
      title="User Settings"
      settings={Object.entries(userSettings).map(([id, setting]) => ({
        id: id,
        name: id,
        value: setting as string,
      }))}
    />
  );
};

export default UserSettingsPage;
