/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Editor from "../components/Editor";
import { H1 } from "@robbie-cook/react-components";
import Showdown from "showdown";
import { ProjectAPI } from "../api/API";

const showdown = new Showdown.Converter();

const EditorPage: React.FC<{ file: string; project: string }> = (props) => {
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    ProjectAPI.getFile(props.project, props.file)
      .then((mytext) => mytext.text())
      .then((raw) => {
        const html = showdown.makeHtml(raw);

        if (html) {
          setText(html);
        }
      });
  }, []);

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <H1>{props.file}</H1>
      <Editor
        text={text}
        onChange={async (html) => {
          ProjectAPI.writeFile(
            props.project,
            props.file,
            showdown.makeMarkdown(html)
          );
        }}
      />
    </div>
  );
};

export default EditorPage;
