/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Button } from "antd";

/**
 * Interface for FileTile props
 */
export interface TileProps {
  name: string;
  onClick: () => void;
  onRemove?: () => void;
  onSettingsClick?: () => void;
}

/**
 *  A FileTile component.
 */
const Tile: React.FC<TileProps> = (props) => {
  return (
    <div
      css={css`
        padding: 30px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        border: 1px solid gray;
        max-width: 300px;
      `}
      onClick={() => props.onClick()}
    >
      <span
        css={css`
          padding: 0 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {props.name}
      </span>
      {props.onRemove && (
        <Button
          danger
          onClick={(e) => {
            e.stopPropagation();
            // @ts-ignore
            props.onRemove();
          }}
        >
          Remove
        </Button>
      )}
      {props.onSettingsClick && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            // @ts-ignore
            props.onSettingsClick();
          }}
        >
          Settings
        </Button>
      )}
    </div>
  );
};

export default Tile;
